import { Box, Fade, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { MetricWithValue } from './MetricWithValue'
import { useDashboardStore } from '../store/dashboard.store'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'

export const GrowthCard = observer(() => {
  const { translation } = useLocalizationStore()
  const dashboardStore = useDashboardStore()

  const translations = translation.pages.myTeam

  const { isLoading: downloadReport } = dashboardStore.getGenericFieldsState('downloadReport')
  const { isLoading: teamOverview } = dashboardStore.getGenericFieldsState('getTeamCourseOverview')

  return (
    <Box sx={styles.root}>
      <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
        <Title1>{translations.teamHeader()}</Title1>
        <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{translations.teamDesc()}</Typography>
      </AppBox>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={teamOverview}></AppProgressLine>
      <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='large' label={translations.metricsHeader.totalUsers()} value={dashboardStore.teamCourseOverview?.totalUsers || 0} />
          <MetricWithValue size='large' label={translations.metricsHeader.activeUsers()} value={dashboardStore.teamCourseOverview?.activeUsers || 0} />
          <MetricWithValue size='large' label={translations.metricsHeader.inactiveUsers()} value={dashboardStore.teamCourseOverview?.inactiveUsers || 0} />
        </AppBox>
      </Fade>
      <AppButton
        fontSize={15}
        variant='contained'
        size='small'
        color="primary"
        sx={{ width: { xs: '100%', md: 'unset' } }}
        onClick={() => dashboardStore.downloadReport()}
      >{translations.downloadTeamReportButton()}</AppButton>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={downloadReport}></AppProgressLine>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    alignItems: 'center',
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' },
    columnGap: { xs: '0.5rem', md: '1.5rem' },
  },
}
