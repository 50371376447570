import { observer } from 'mobx-react-lite'
import { GrowthCard } from '../component/GrowthCard'
import { Box } from '@mui/material'
import { DepartmentList } from '../component/DepartmentList'
// import { SkillGapList } from '../component/SkillGapList'
// import { useUserStore } from 'src/feature/user/store/user.store'
import { useDashboardStore } from '../store/dashboard.store'
import { useEffect, useState } from 'react'

export const AdministratorDasboardPage = observer(() => {
  // const userStore = useUserStore()
  const dashboardStore = useDashboardStore()

  const [showList, setShowList] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      await dashboardStore.getTeamCourseOverview().then(() => {
        setShowList(true)
      })
    }
    fetchData()
  }, [])

  return (
    <Box sx={styles.root}>
      {showList && <GrowthCard />}
      {showList && <DepartmentList />}
      {/* {(userStore.sitemap?.modules ?? []).filter(s => s.key === 'teamDashboard' && s.level === 'proplus').length > 0 && showList && <SkillGapList />} */}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4rem',
    padding: { xs: '1rem 1rem', md: '2rem 3rem' },
  },
}
