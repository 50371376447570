import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { AppForm } from 'src/component/common/analog/molecule/basic/AppForm'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import OrgLevelsSelector from 'src/feature/e-learning/component/organism/OrgLevelsSelector'
import { useB2bStore } from 'src/feature/b2b/store/b2b.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { useControlPanelStore } from 'src/feature/control-panel/store/control-panel.store'
import { OrganisationRole } from 'src/feature/control-panel/model/OrganisationRole'
import RoleSelector from 'src/feature/control-panel/component/RoleSelector'
import { AppDivider } from 'src/component/common/analog/molecule/AppDivider'

export type AddUserModalProps = {
  levelId: string
  show: boolean
  onClose: (reload: boolean) => void
}

export const AddUser = observer((props: AddUserModalProps) => {
  const { translation } = useLocalizationStore()
  const texts = translation.pages.controlpanelUsers.inviteDialog

  const b2bStore = useB2bStore()
  const controlPanelStore = useControlPanelStore()

  const [emails, setEmails] = useState('')
  const [orgLevels, setOrgLevels] = useState<string[]>([props.levelId])
  const [role, setRole] = useState(OrganisationRole.User)
  const { isLoading } = controlPanelStore.getGenericFieldsState('inviteUser')

  useEffect(() => {
    b2bStore.handleLoadOrganisationLevels()
  }, [])

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmails(event.target.value)
  }

  const createInvite = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await controlPanelStore.addUser(emails, orgLevels, role)
    props.onClose(true)
  }

  return (
    <ModalWindow
      modalProps={{
        open: props.show,
        onClose: () => props.onClose(false),
      }}
      topProps={{
        mb: 28,
      }}
      titleProps={{
        textValue: texts.title,
      }}
    >
      <AppBox sx={styles.container}>
        <AppForm sx={styles.form} onSubmit={createInvite}>
          <AppTextField label={texts.emailField} onChange={handleEmailChange} fullWidth required style={{ marginBottom: 0 }} />
          <Caption textValue={texts.emailHelp} style={{ marginBottom: '1.5em' }} />
          <OrgLevelsSelector label={texts.groupField()} placeholder='' defaultValues={orgLevels} disabled={true} onChange={setOrgLevels} organiationLevels={b2bStore.organisationLevels} />
          <RoleSelector label={texts.roleField()} placeholder='' defaultValue={role} disabled={true} onChange={setRole} />
          <AppDivider sx={{ my: 7 }} />
          <AppBox sx={{ gap: 10, justifyContent: 'flex-end', width: '100%' }}>
            <AppButton
              textValue={translation.buttons.cancel}
              fontSize={15}
              size='small'
              variant="text"
              color="primary"
              onClick={() => props.onClose(false)}
            />
            <AppButton
              textValue={texts.sendButton}
              fontSize={15}
              size='small'
              variant="contained"
              color="primary"
              disabled={isLoading}
              type='submit'
            />
          </AppBox>
        </AppForm>
        <AppProgressLine isShowing={isLoading} />
      </AppBox>
    </ModalWindow>
  )
})

const styles = {
  container: {
    flexDirection: 'column',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}