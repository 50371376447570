export enum FormFieldId {
  NAME = 'name',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  LOCALITY = 'locality',
  PHONE_NUMBER = 'phoneNumber',
  PROFESSION = 'profession',
  QUESTION = 'question',
  MESSAGE = 'message',
  GROSS_SALARY = 'grossSalary',
  PROMO_CODE = 'promoCode',
  STUDENT_CODE = 'studentCode',
  ADDRESS = 'address',
  ZIP = 'zip',
  PERSONAL_NUMBER = 'personalNumber',
  ALIAS = 'alias',
  FILE = 'file',
  COMPANY_NAME = 'companyName',
  SKILL_ANALYSIS_LANGUAGE = 'skillAnalysisLanguage',
  SAML_METADATA_URL = 'samlMetadataUrl',
  LOGIN_URL = 'loginUrl',
  LOGIN_MESSAGE = 'loginMessage',
  LOGIN_ISSUE_CONTACT = 'loginIssueContact',
}
