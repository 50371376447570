import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CoursePlanItemProgress } from '../../model/ELearningCoursePlanItem'
import { CircularProgressWithLabel } from './CircularProgressWithLabel'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import { Tooltip, Typography } from '@mui/material'

export type CourseProgressRowProp = {
  progress?: CoursePlanItemProgress
  isProgressBar?: boolean
}

export const CourseProgressRow = observer(({ progress, isProgressBar = true }: CourseProgressRowProp) => {
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning

  return (
    <AppBox sx={styles.row}>
      {isProgressBar && <CircularProgressWithLabel value={progress?.percentage || 0} />}
      {!isProgressBar && <Typography sx={{ fontSize: '1.3em', fontWeight: 500 }}>{progress?.percentage}%</Typography>}
      <Tooltip title={(progress?.testPassed ? texts.testPassed() : texts.testNotPassed())} arrow><MilitaryTechIcon sx={{ color: (progress?.testPassed ? 'secondary.main' : 'text.secondary'), height: '1.2em', width: '1.2em' }} /></Tooltip>
    </AppBox>
  )
})

const styles = {
  row: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 3,
  },
}
