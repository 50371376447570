import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Palette } from 'src/feature/theme/model/Palette'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { useEffect, useState } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import StepButton from '@mui/material/StepButton'
import { Tooltip, Typography, useTheme, Zoom } from '@mui/material'
import { useSkillAssessmentStore } from 'src/feature/skill-assessment/store/skill-assessment.store'
import { SkillSet, SkillSetLevel } from 'src/feature/skill-assessment/model/skill-assessment-model'
import { addExpectedLevel } from 'src/feature/skill-assessment/api/skill-assessment-api'
import HelpIcon from '@mui/icons-material/Help'
import { AppDivider } from 'src/component/common/analog/molecule/AppDivider'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export type ExpectedLevelsProps = {
  isModalClose: () => void
  isModalOpen: boolean
  skillAssessmentSetIndex: number
}

export const ExpectedLevels = observer((props: ExpectedLevelsProps) => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam.rolesButton
  const skillAssessmentStore = useSkillAssessmentStore()
  const skillAssessmentSet = skillAssessmentStore.skillCategory ?? { skills: [] }
  const [activeStep, setActiveStep] = useState<number>(props.skillAssessmentSetIndex)
  const theme = useTheme()

  useEffect(() => {
    setActiveStep(props.skillAssessmentSetIndex)
  }, [props.skillAssessmentSetIndex])

  const addExpectedRating = async (setId: string, setName: string, expectedSetLevel: number) => {
    await addExpectedLevel(setId, setName, expectedSetLevel)
    skillAssessmentSet.skills[activeStep].expected_set_level = expectedSetLevel
  }

  const handleBackStep = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  const handleFinish = () => {
    props.isModalClose()
  }

  return (
    <>
      <ModalWindow
        modalProps={{
          open: props.isModalOpen,
          onClose: () => props.isModalClose(),
          PaperProps: {
            sx: {
              maxWidth: { xs: '90%', md: '80%', lg: '60%' },
              borderRadius: '20px',
            },
          },
        }}
      >
        <Zoom in={props.isModalOpen} timeout={theme.transitions.duration.standard}>
          <AppBox sx={styles.mainContainer}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ width: { xs: '90%', md: '80%', lg: '60%' }, justifyContent: 'center' }}>
              {skillAssessmentSet?.skills.map((skill: SkillSet, index: number) => (
                <Step key={skill.id} completed={skill.expected_set_level !== null && skill.expected_set_level !== 0}>
                  <StepButton
                    onClick={() => setActiveStep(index)}
                    sx={{
                      '& .MuiStepIcon-root': {
                        color: activeStep === index ? `${Palette.CORAL_REEF_GREEN} !important` : '',
                      },
                      '& .Mui-completed': {
                        color: 'alternate.background',
                      },
                    }}
                  ></StepButton>
                </Step>
              ))}
            </Stepper>
            <AppBox sx={{ justifyContent: 'center' }}>
              <Typography variant='h1' m={0} sx={{ color: 'text.primary' }}>{skillAssessmentSet?.skills[activeStep].setName}</Typography>
              <Tooltip sx={{ color: Palette.CORAL_REEF_GREEN }} title={skillAssessmentSet?.skills[activeStep].setHint} arrow>
                <HelpIcon fontSize='medium' />
              </Tooltip>
            </AppBox>
            <AppBox sx={styles.skillsContainer}>
              {skillAssessmentSet?.skills[activeStep]?.levels.map((level: SkillSetLevel, index: number) => (
                <AppPaper
                  sx={{
                    ...styles.descriptionContainer,
                    backgroundColor: level?.setLevel === skillAssessmentSet?.skills[activeStep].expected_set_level ? 'alternate.background' : '',
                    '&:hover P': {
                      color: `alternate.text`,
                    },
                  }}
                  key={level?.id}
                  onClick={() => addExpectedRating(skillAssessmentSet?.skills[activeStep].id, skillAssessmentSet?.skills[activeStep].setName, level?.setLevel)}
                >
                  <Typography
                    variant='subtitle1' m={0}
                    color= {level?.setLevel !== skillAssessmentSet?.skills[activeStep].expected_set_level ? `alternate.background` : `alternate.text`}
                  >{`${index + 1}. ${level?.setLevelDescription}`}</Typography>
                </AppPaper>
              ))}
            </AppBox>
            <AppDivider />
            <AppBox sx={styles.buttonContainer}>
              <AppButton disabled={activeStep === 0} fontSize={15} size='small' variant="text" color="primary" onClick={handleBackStep}>{translations.backButton()}</AppButton>
              {activeStep === skillAssessmentSet.skills.length - 1 ? (
                <AppButton onClick={handleFinish} fontSize={15} size='small' variant="contained" color="primary">{translations.finishButton()}</AppButton>
              ) : (
                <AppButton fontSize={15} size='small' variant="contained" color="primary" onClick={() => setActiveStep(prevActiveStep => prevActiveStep + 1)}>{translations.nextButton()}</AppButton>
              )}
            </AppBox>
          </AppBox>
        </Zoom>
      </ModalWindow>
    </>
  )
})

const styles = {
  mainContainer: {
    marginTop: '0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  skillsContainer: {
    flexDirection: 'column',
    gap: 10,
    width: '100%',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  descriptionContainer: {
    borderColor: 'alternate.background',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'alternate.background',
      opacity: 0.8,
    },
    borderRadius: '15px',
    padding: '0.7em',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
  buttonContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    gap: 10,
  },
}
