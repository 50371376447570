import { GenericStore } from 'src/util/mobx/generic-store/generic.store'
import { addSkillCategory, addSkillSet, deleteExpectedRole, deleteSkillSet, getProfessions, getSkillCategory, getSkillSets } from '../api/skill-assessment-api'
import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { createStore } from 'src/util/mobx/generic-store/store-creator'
import { ProfessionType, SkillCategory } from '../model/skill-assessment-model'

export class skillAssessmentStore extends GenericStore {
  skillSets: SkillCategory | undefined
  professionsData: ProfessionType[] | undefined
  skillCategory: SkillCategory | undefined

  constructor() {
    super('skillAssessmentStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  reset() {
    this.skillSets = undefined
    this.professionsData = undefined
    this.skillCategory = undefined
  }

  @GenericStoreAsyncMethod()
  async getSkillSets(userId?: string) {
    const data: any = await getSkillSets(userId)
    this.skillSets = data
  }

  @GenericStoreAsyncMethod()
  async getProfessions() {
    const data: ProfessionType[] = await getProfessions()
    // remove duplicates from professions data
    const uniqueProfessions = data.filter((v, i, a) => a.findIndex(t => (t.title === v.title)) === i)
    this.professionsData = uniqueProfessions
  }

  @GenericStoreAsyncMethod()
  async getSkillCategory(levelId: string) {
    const data: SkillCategory = await getSkillCategory(levelId)
    this.skillCategory = data
  }

  @GenericStoreAsyncMethod()
  async addSkillCategory(profession: string, levelId: string) {
    await addSkillCategory(profession, levelId)
  }

  @GenericStoreAsyncMethod()
  async deleteExpectedRole(levelId: string) {
    await deleteExpectedRole(levelId)
  }

  @GenericStoreAsyncMethod()
  async deleteSkillSet(setId: string, categoryId: string) {
    await deleteSkillSet(setId, categoryId)
  }

  @GenericStoreAsyncMethod()
  async addSkillSet(skillSetTitle: string, categoryId: string) {
    await addSkillSet(skillSetTitle, categoryId)
  }
}

export const {
  storeInstance: skillAssessmentStoreInstance,
  useStore: useSkillAssessmentStore,
} = createStore(new skillAssessmentStore())
