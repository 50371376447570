import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { AppCheckbox } from 'src/feature/form/component/analog/AppCheckbox'
import { AppDatePicker } from 'src/feature/form/component/analog/AppDatePicker'
import { AppFormControlWithLabel } from 'src/feature/form/component/analog/AppFormControlWithLabel'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import OrgLevelsSelector from './OrgLevelsSelector'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useB2bStore } from 'src/feature/b2b/store/b2b.store'
import { AppCircleLoader } from 'src/component/common/analog/molecule/loader/AppCircleLoader'
import { Collapse, Tooltip } from '@mui/material'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { Palette } from 'src/feature/theme/model/Palette'

export type OrgSettingsModalProps = {
  show: boolean
  onClose: () => void
  courseId: string
  onDeleteCoursePlanItem?: () => void
}

export const OrgSettingsModal = observer((props: OrgSettingsModalProps) => {
  const { translation } = useLocalizationStore()
  const b2bStore = useB2bStore()
  const { orgSettings } = translation.pages.eLearning
  const [mandatory, setMandatory] = useState(false)
  const [deadline, setDeadline] = useState<Date | undefined>(new Date())
  const [anyLanguage, setAnyLanguage] = useState(true)
  const [includeSubLevels, setIncludeSubLevels] = useState(false)
  const [orgLevels, setOrgLevels] = useState<string[]>([])
  const [deleteCoursePlanItem, setDeleteCoursePlanItem] = useState(false)
  const saveState = b2bStore.getGenericFieldsState('saveCourseOrganisationPlanItem')
  const loadState = b2bStore.getGenericFieldsState('loadCourseOrganisationPlanItem')
  const loadOrgLevelState = b2bStore.getGenericFieldsState('handleLoadOrganisationLevels')

  useEffect(() => {
    b2bStore.handleLoadOrganisationLevels()
    b2bStore.loadCourseOrganisationPlanItem(props.courseId).then(() => {
      if (b2bStore.courseOrganisationSettings) {
        setMandatory(b2bStore.courseOrganisationSettings.mandatory)
        setDeadline(b2bStore.courseOrganisationSettings.deadline)
        setAnyLanguage(b2bStore.courseOrganisationSettings.allLanguages || false)
        setIncludeSubLevels(b2bStore.courseOrganisationSettings.includeInOrganisationSubLevels)
        setOrgLevels(b2bStore.courseOrganisationSettings.organisationLevels)
        setDeleteCoursePlanItem(true)
      }
    })
  }, [props.courseId])

  const savePlanItem = async () => {
    await b2bStore.saveCourseOrganisationPlanItem({
      id: b2bStore.courseOrganisationSettings?.id || '',
      includeInOrganisationSubLevels: includeSubLevels,
      mandatory,
      deadline: mandatory ? deadline : undefined,
      organisationLevels: orgLevels.some(o => o === '') ? [] : orgLevels,
      allLanguages: anyLanguage,
    }, props.courseId)
    props.onClose()
  }

  const removeItem = async () => {
    await b2bStore.saveCourseOrganisationPlanItem(null, props.courseId)
    props.onDeleteCoursePlanItem && props.onDeleteCoursePlanItem()
    props.onClose()
  }

  return (
    <ModalWindow
      modalProps={{
        open: props.show,
        onClose: props.onClose,
      }}
      topProps={{
        mb: 28,
      }}
      titleProps={{ textValue: orgSettings.description }}
    >
      {loadState.isLoading || loadOrgLevelState.isLoading ? <AppCircleLoader isShowing={true} />
        : <AppBox sx={styles.container}>
          <AppBox sx={{ gap: 10 }}>
            <AppFormControlWithLabel control={<AppCheckbox size='medium' checked={mandatory} id='mandatory' onClick={() => setMandatory(!mandatory)} />} label={orgSettings.mandatoryCheckbox} />
            <AppFormControlWithLabel control={<AppCheckbox size='medium' checked={anyLanguage} id='anyLanguage' onClick={() => setAnyLanguage(!anyLanguage)} />} label={orgSettings.allowAllLanguages} />
          </AppBox>
          <Collapse in={mandatory}>
            <AppDatePicker label={orgSettings.deadlineLabel()} id='deadline' disabled={!mandatory} key="start-date" defaultValue={deadline} onChange={setDeadline} />
          </Collapse>
          <OrgLevelsSelector label={orgSettings.targetAudience()} placeholder='' defaultValues={orgLevels.length === 0 ? [''] : orgLevels} onChange={setOrgLevels} organiationLevels={b2bStore.organisationLevels} showOptionForAll={true} />
          <AppFormControlWithLabel control={<AppCheckbox size='medium' checked={includeSubLevels} id='anyLanguage' onClick={() => setIncludeSubLevels(!includeSubLevels)} />} label={orgSettings.inheritSubLevels} />
          {saveState.isLoading && <AppCircleLoader isShowing={true} />}
          {!saveState.isLoading && <>
            <AppBox sx={{ gap: 10, justifyContent: 'flex-end' }}>
              <AppButton
                textValue={translation.buttons.cancel}
                fontSize={15}
                size='small'
                variant="text"
                color="primary"
                onClick={props.onClose}
              />
              <AppButton
                textValue={orgSettings.saveButton}
                fontSize={15}
                size='small'
                variant="contained"
                color="primary"
                onClick={savePlanItem}
              />
              {deleteCoursePlanItem && <AppIconButton sx={styles.iconButton} onClick={() => removeItem()}>
                <Tooltip title={translation.pages.eLearning.removeCourseForAll()} arrow><DeleteIcon fontSize='medium' /></Tooltip>
              </AppIconButton>}
            </AppBox>
          </>}
        </AppBox>}
    </ModalWindow>
  )
})

const styles = {
  container: {
    flexDirection: 'column',
    gap: 2,
  },
  iconButton: {
    color: 'text.primary',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: Palette.DANGER,
    },
  },
}